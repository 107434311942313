import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  faSearch,
  faEye,
  faList,
  faTh,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { NftService } from '../services/nft.service';
import { ScEventsService } from '../services/sc-events.service';
import { SharedService } from '../services/shared.service';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent implements OnInit {
  clickEventSubscription: Subscription;
  slideEventSubscription: Subscription;
  faSearch = faSearch;
  faHeart = faHeart;
  faEye = faEye;
  faTh = faTh;
  faList = faList;
  isGrid = true;
  nftList;
  filterAnimalList;
  filterGameList;
  filterCount = 12;
  totalList;
  totalCount;
  animalFilterStat = false;
  gameFilterStat = false;
  listedFilterSat = false;
  errMsg;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private nftService: NftService,
    // private marketPlace: MarketplaceService,
    // private nftContract: NftContractService,
    private contractEvent: ScEventsService,
    private sharedService: SharedService,
    private userService: UsersService
  ) {
    this.clickEventSubscription = this.sharedService
      .getClickEvent()
      .subscribe(async (data) => {
        if (data.value === 'animal') {
          this.animalFilterStat = data.status;
        } else if (data.value === 'game') {
          this.gameFilterStat = data.status;
        } else if (data.value === 'listed') {
          this.listedFilterSat = data.status;
        }
        this.filterNftByType();
      });

    this.clickEventSubscription = this.sharedService
      .getSlideEvent()
      .subscribe(async (data) => {
        await this.filterNftByPrice(data.value, data.highValue);
      });
  }

//  async ngOnInit(): Promise<void> {
//     try {
//       this.userService.checkMetaMask();

//      // Get animal nft available in marketplace
//       await this.nftService.getAllAnimalNft().subscribe((data) => {
//         if (data.success && data.result.length > 0) {
//           this.totalList = data.result;
//         }
//       });
   
//       await this.nftService.getAllGameNft().subscribe((data) => {
//         if (data.success && data.result.length > 0) {
//           this.totalList = this.totalList.concat(data.result);
//           this.totalList.sort((a, b) => b.price - a.price);
//           this.nftList = this.totalList.slice(0, this.filterCount);
//           this.totalCount = this.totalList.length;
//           this.sortBy(1);
//           console.log('total', this.totalList);
//         }
//       });
//     } catch (e) {
//       console.log("error", e)
//     }
//   }

  async ngOnInit(): Promise<void> {
    try {
      this.userService.checkMetaMask();

      this.nftList = [
        {
          "animal_attributes": [
            {
              "trait_type": "Gender",
              "value": "Male"
            },
            {
              "trait_type": "Eye Color",
              "value": "Orange"
            },
            {
              "trait_type": "Hair Color",
              "value": "Orange"
            },
            {
              "trait_type": "Hair Length",
              "value": "To Ears"
            },
            {
              "trait_type": "Age",
              "value": "17"
            },
            {
              "display_type": "boost_percentage",
              "trait_type": "Move Speed",
              "value": "5"
            },
            
          ],
          "animal_description": "A super robot Eiji is a boy who used to be raised by his sister Ayaka, who he is searching for. He has a short temper and is either annoyed by Toga or Luna.",
          "animal_name": "Eiji Shigure",
          "animal_type": 0,
          "contract_address": "0x4edd7580fa191c7f05a92c6f9f06b25dffcd0d6f",
          "created_at": "2022-07-08T06:51:17.254Z",
          "extra_attributes": [
            {
              "trait_type": "Mecha allowed",
              "value": "God Gravion, Gran Kaiser, G Shadow, G Driller,  G Attacker, G Striker"
            }
          ],
          "favourite": [],
          "file_type": "png",
          "blockchain": "sepolia",
          "media_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmeCtc7vf6Pm4gkqNRFhqtrCWXYyCtLNq9e7qcHkEohEGS/1.png",
          "metadata_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmZnjpzPcYxmpTmnmfz3xskpMyz5uDgiWtu99DVGxnFcvB/1.json",
          "tokenId": "1",
          "royalty": 10,
          "price": 0,
          "creator": "0xDC7856dd0D8527c12C3e725fb7D7dEd235850D61",
          "owner": "0xecc3D3B1862D73A8f5ee1DD2477d52397468a3b2 ",
          "token_address": "0xcea34fa634f6991dcf746047855d06b7326b979b",
          "updated_at": "2023-04-06T17:01:04.874Z"
        },
        {
          "animal_attributes": [
            {
              "display_type": "boost_number",
              "trait_type": "ATK",
              "value": "50"
            },
            {
              "display_type": "boost_percentage", 
              "trait_type": "CRIT",
              "value": "5"
            }
          ],
          "animal_description": "A super robot developed by Sandman to defend the Earth. It was given the role of protecting the Earth as a \"shield.\"\r\nIt is born when Gran Kaiser combines with four Grandivas, G Attacker, G Striker, G Driller, and G Shadow, to form a \"Super Polymerization God\". It boasts high combat capabilities because it always activates its Ergo Form when and after it combines.",
          "animal_name": "God Gravion",
          "animal_type": 0,
          "contract_address": "0x4edd7580fa191c7f05a92c6f9f06b25dffcd0d6f",
          "created_at": "2022-07-08T06:51:17.254Z",
          "extra_attributes": [
            {
              "trait_type": "Pilot",
              "value": "Touga Tenkuuji, Eiji Shigure"
            }
          ],
          "favourite": [],
          "file_type": "png",
          "blockchain": "sepolia",
          "media_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmPk65TxTvxWnCv6XB3VP9Mi9k4JED8USumJSNdkieSg25/1.png",
          "metadata_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmPk65TxTvxWnCv6XB3VP9Mi9k4JED8USumJSNdkieSg25/1.json",
          "tokenId": "2",
          "royalty": 10,
          "price": 0,
          "creator": "0xDC7856dd0D8527c12C3e725fb7D7dEd235850D61",
          "owner": "0xecc3D3B1862D73A8f5ee1DD2477d52397468a3b2 ",
          "token_address": "0xcea34fa634f6991dcf746047855d06b7326b979b",
          "updated_at": "2023-04-06T17:01:04.874Z"
        },
        {
          "animal_attributes": [
            {
              "display_type": "boost_number",
              "trait_type": "ATK",
              "value": "50"
            },
            {
              "display_type": "boost_percentage", 
              "trait_type": "CRIT",
              "value": "5"
            }
          ],
          "animal_description": "Born to defy limitations, the pistol whispers defiance. Witness the artistry of precision, as the pistol becomes an extension of your will, empowering you to conquer all.",
          "animal_name": "Pistol MK II",
          "animal_type": 0,
          "contract_address": "0x4edd7580fa191c7f05a92c6f9f06b25dffcd0d6f",
          "created_at": "2022-07-08T06:51:17.254Z",
          "extra_attributes": [
            {"trait_type": "Gender", "value": "Male"},
            {"trait_type": "Eye Color", "value": "Blue"},
            {"trait_type": "Hair Color", "value": "Blue"},
            {"trait_type": "Hair Lenght", "value": "To Ears"},
            {"trait_type": "Age", "value": "17"},
            {"trait_type": "Move Speed", "value": "+10%"}
          ],
          "favourite": [],
          "file_type": "png",
          "blockchain": "sepolia",
          "media_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmVv9Kqr21ojF8tzFK2AmLG2EAmFrAvS61QbpLAL8mfRow/1.png",
          "metadata_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmVv9Kqr21ojF8tzFK2AmLG2EAmFrAvS61QbpLAL8mfRow/1.json",
          "tokenId": "3",
          "royalty": 10,
          "price": 0,
          "creator": "0xDC7856dd0D8527c12C3e725fb7D7dEd235850D61",
          "owner": "0xecc3D3B1862D73A8f5ee1DD2477d52397468a3b2 ",
          "token_address": "0xcea34fa634f6991dcf746047855d06b7326b979b",
          "updated_at": "2023-04-06T17:01:04.874Z"
        },
        {
          "animal_attributes": [
            {
              "trait_type": "Gender",
              "value": "Male"
            },
            {
              "trait_type": "Eye Color",
              "value": "Orange"
            },
            {
              "trait_type": "Hair Color",
              "value": "Orange"
            },
            {
              "trait_type": "Hair Length",
              "value": "To Ears"
            },
            {
              "trait_type": "Age",
              "value": "17"
            },
            {
              "display_type": "boost_percentage",
              "trait_type": "Move Speed",
              "value": "5"
            },
            
          ],
          "animal_description": "A super robot Eiji is a boy who used to be raised by his sister Ayaka, who he is searching for. He has a short temper and is either annoyed by Toga or Luna.",
          "animal_name": "Eiji Shigure",
          "animal_type": 0,
          "contract_address": "0x4edd7580fa191c7f05a92c6f9f06b25dffcd0d6f",
          "created_at": "2022-07-08T06:51:17.254Z",
          "extra_attributes": [
            {
              "trait_type": "Mecha allowed",
              "value": "God Gravion, Gran Kaiser, G Shadow, G Driller,  G Attacker, G Striker"
            }
          ],
          "favourite": [],
          "file_type": "png",
          "blockchain": "sepolia",
          "media_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmeCtc7vf6Pm4gkqNRFhqtrCWXYyCtLNq9e7qcHkEohEGS/1.png",
          "metadata_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmZnjpzPcYxmpTmnmfz3xskpMyz5uDgiWtu99DVGxnFcvB/1.json",
          "tokenId": "4",
          "royalty": 10,
          "price": 0,
          "creator": "0xDC7856dd0D8527c12C3e725fb7D7dEd235850D61",
          "owner": "0xecc3D3B1862D73A8f5ee1DD2477d52397468a3b2 ",
          "token_address": "0xcea34fa634f6991dcf746047855d06b7326b979b",
          "updated_at": "2023-04-06T17:01:04.874Z"
        },
        {
          "animal_attributes": [
            {
              "display_type": "boost_number",
              "trait_type": "ATK",
              "value": "50"
            },
            {
              "display_type": "boost_percentage", 
              "trait_type": "CRIT",
              "value": "5"
            }
          ],
          "animal_description": "A super robot developed by Sandman to defend the Earth. It was given the role of protecting the Earth as a \"shield.\"\r\nIt is born when Gran Kaiser combines with four Grandivas, G Attacker, G Striker, G Driller, and G Shadow, to form a \"Super Polymerization God\". It boasts high combat capabilities because it always activates its Ergo Form when and after it combines.",
          "animal_name": "God Gravion",
          "animal_type": 0,
          "contract_address": "0x4edd7580fa191c7f05a92c6f9f06b25dffcd0d6f",
          "created_at": "2022-07-08T06:51:17.254Z",
          "extra_attributes": [
            {
              "trait_type": "Pilot",
              "value": "Touga Tenkuuji, Eiji Shigure"
            }
          ],
          "favourite": [],
          "file_type": "png",
          "blockchain": "sepolia",
          "media_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmPk65TxTvxWnCv6XB3VP9Mi9k4JED8USumJSNdkieSg25/1.png",
          "metadata_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmPk65TxTvxWnCv6XB3VP9Mi9k4JED8USumJSNdkieSg25/1.json",
          "tokenId": "5",
          "royalty": 10,
          "price": 0,
          "creator": "0xDC7856dd0D8527c12C3e725fb7D7dEd235850D61",
          "owner": "0xecc3D3B1862D73A8f5ee1DD2477d52397468a3b2 ",
          "token_address": "0xcea34fa634f6991dcf746047855d06b7326b979b",
          "updated_at": "2023-04-06T17:01:04.874Z"
        },
        {
          "animal_attributes": [
            {
              "display_type": "boost_number",
              "trait_type": "ATK",
              "value": "50"
            },
            {
              "display_type": "boost_percentage", 
              "trait_type": "CRIT",
              "value": "5"
            }
          ],
          "animal_description": "Born to defy limitations, the pistol whispers defiance. Witness the artistry of precision, as the pistol becomes an extension of your will, empowering you to conquer all.",
          "animal_name": "Pistol MK II",
          "animal_type": 0,
          "contract_address": "0x4edd7580fa191c7f05a92c6f9f06b25dffcd0d6f",
          "created_at": "2022-07-08T06:51:17.254Z",
          "extra_attributes": [
            {"trait_type": "Gender", "value": "Male"},
            {"trait_type": "Eye Color", "value": "Blue"},
            {"trait_type": "Hair Color", "value": "Blue"},
            {"trait_type": "Hair Lenght", "value": "To Ears"},
            {"trait_type": "Age", "value": "17"},
            {"trait_type": "Move Speed", "value": "+10%"}
          ],
          "favourite": [],
          "file_type": "png",
          "blockchain": "sepolia",
          "media_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmVv9Kqr21ojF8tzFK2AmLG2EAmFrAvS61QbpLAL8mfRow/1.png",
          "metadata_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmVv9Kqr21ojF8tzFK2AmLG2EAmFrAvS61QbpLAL8mfRow/1.json",
          "tokenId": "6",
          "royalty": 10,
          "price": 0,
          "creator": "0xDC7856dd0D8527c12C3e725fb7D7dEd235850D61",
          "owner": "0xecc3D3B1862D73A8f5ee1DD2477d52397468a3b2 ",
          "token_address": "0xcea34fa634f6991dcf746047855d06b7326b979b",
          "updated_at": "2023-04-06T17:01:04.874Z"
        },
        {
          "animal_attributes": [
            {
              "trait_type": "Gender",
              "value": "Male"
            },
            {
              "trait_type": "Eye Color",
              "value": "Orange"
            },
            {
              "trait_type": "Hair Color",
              "value": "Orange"
            },
            {
              "trait_type": "Hair Length",
              "value": "To Ears"
            },
            {
              "trait_type": "Age",
              "value": "17"
            },
            {
              "display_type": "boost_percentage",
              "trait_type": "Move Speed",
              "value": "5"
            },
            
          ],
          "animal_description": "A super robot Eiji is a boy who used to be raised by his sister Ayaka, who he is searching for. He has a short temper and is either annoyed by Toga or Luna.",
          "animal_name": "Eiji Shigure",
          "animal_type": 0,
          "contract_address": "0x4edd7580fa191c7f05a92c6f9f06b25dffcd0d6f",
          "created_at": "2022-07-08T06:51:17.254Z",
          "extra_attributes": [
            {
              "trait_type": "Mecha allowed",
              "value": "God Gravion, Gran Kaiser, G Shadow, G Driller,  G Attacker, G Striker"
            }
          ],
          "favourite": [],
          "file_type": "png",
          "blockchain": "sepolia",
          "media_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmeCtc7vf6Pm4gkqNRFhqtrCWXYyCtLNq9e7qcHkEohEGS/1.png",
          "metadata_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmZnjpzPcYxmpTmnmfz3xskpMyz5uDgiWtu99DVGxnFcvB/1.json",
          "tokenId": "7",
          "royalty": 10,
          "price": 0,
          "creator": "0xDC7856dd0D8527c12C3e725fb7D7dEd235850D61",
          "owner": "0xecc3D3B1862D73A8f5ee1DD2477d52397468a3b2 ",
          "token_address": "0xcea34fa634f6991dcf746047855d06b7326b979b",
          "updated_at": "2023-04-06T17:01:04.874Z"
        },
        {
          "animal_attributes": [
            {
              "display_type": "boost_number",
              "trait_type": "ATK",
              "value": "50"
            },
            {
              "display_type": "boost_percentage", 
              "trait_type": "CRIT",
              "value": "5"
            }
          ],
          "animal_description": "A super robot developed by Sandman to defend the Earth. It was given the role of protecting the Earth as a \"shield.\"\r\nIt is born when Gran Kaiser combines with four Grandivas, G Attacker, G Striker, G Driller, and G Shadow, to form a \"Super Polymerization God\". It boasts high combat capabilities because it always activates its Ergo Form when and after it combines.",
          "animal_name": "God Gravion",
          "animal_type": 0,
          "contract_address": "0x4edd7580fa191c7f05a92c6f9f06b25dffcd0d6f",
          "created_at": "2022-07-08T06:51:17.254Z",
          "extra_attributes": [
            {
              "trait_type": "Pilot",
              "value": "Touga Tenkuuji, Eiji Shigure"
            }
          ],
          "favourite": [],
          "file_type": "png",
          "blockchain": "sepolia",
          "media_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmPk65TxTvxWnCv6XB3VP9Mi9k4JED8USumJSNdkieSg25/1.png",
          "metadata_uri": "https://blush-biological-mink-848.mypinata.cloud/ipfs/QmPk65TxTvxWnCv6XB3VP9Mi9k4JED8USumJSNdkieSg25/1.json",
          "tokenId": "8",
          "royalty": 10,
          "price": 0,
          "creator": "0xDC7856dd0D8527c12C3e725fb7D7dEd235850D61",
          "owner": "0xecc3D3B1862D73A8f5ee1DD2477d52397468a3b2 ",
          "token_address": "0xcea34fa634f6991dcf746047855d06b7326b979b",
          "updated_at": "2023-04-06T17:01:04.874Z"
        },
      ]

      console.log(this.nftList)

      //Get animal nft available in marketplace
      // await this.nftService.getAllAnimalNft().subscribe((data) => {
      //   if (data.success && data.result.length > 0) {
      //     this.totalList = data.result;
      //   }
      // });
      // await this.nftService.getAllGameNft().subscribe((data) => {
      //   if (data.success && data.result.length > 0) {
      //     this.totalList = this.totalList.concat(data.result);
      //     this.totalList.sort((a, b) => b.price - a.price);
      //     this.nftList = this.totalList.slice(0, this.filterCount);
      //     this.totalCount = this.totalList.length;
      //     this.sortBy(1);
      //     console.log('total', this.totalList);
      //   }
      // });
    } catch (e) {}
  }

  onScroll() {
    console.log('scrolled!!');
    if (this.filterCount <= this.totalList?.length) {
      this.filterCount += 10;
      this.nftList = this.totalList.slice(0, this.filterCount);
    }
  }
  showQuickview(content) {
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  open(content, size) {
    this.modalService.open(content, { size: size, centered: true });
  }

  productDetail() {
    this.router.navigate(['product/detail']);
  }

  product() {
    this.router.navigate(['product/detail']);
  }

  gridRow(value) {
    if (value == 1) {
      this.isGrid = true;
    } else {
      this.isGrid = false;
    }
  }

  sortBy(value) {
    this.filterCount = 12;

    if (value == 1) {
      //this.totalList.sort((a, b) => b.price - a.price);
      this.totalList = this.totalList
        .filter((e) => e.price > 0)
        .sort(
          (a, b) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        )
        .concat(this.totalList.filter((e) => e.price == 0));
    } else if (value == 2) {
      this.totalList.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    } else if (value == 3) {
      this.totalList = this.totalList
        .filter((e) => e.price > 0 && e.isListed)
        .sort((a, b) => a.price - b.price)
        .concat(this.totalList.filter((e) => e.price == 0 || !e.isListed));
    } else if (value == 4) {
      this.totalList = this.totalList
        .filter((e) => e.price > 0 && e.isListed)
        .sort((a, b) => b.price - a.price)
        .concat(this.totalList.filter((e) => e.price == 0 || !e.isListed));
    }
    console.log('totalList', this.totalList);
    this.nftList = this.totalList.slice(0, this.filterCount);
  }

  async filterNftByType() {
    this.filterCount = 12;
    this.totalList = null;
    this.nftList = null;

    if (this.animalFilterStat && !this.gameFilterStat) {
      await this.nftService.getAllAnimalNft().subscribe((data) => {
        if (data.success && data.result.length > 0) {
          this.totalList = data.result;
          if (this.listedFilterSat) {
            this.totalList = this.totalList
              .filter((e) => e.price > 0 && e.isListed)
              .sort((a, b) => b.price - a.price);
          } else {
            this.totalList.sort((a, b) => b.price - a.price);
          }
          this.nftList = this.totalList.slice(0, this.filterCount);
          this.totalCount = this.totalList.length;
        }
      });
    } else if (!this.animalFilterStat && this.gameFilterStat) {
      await this.nftService.getAllGameNft().subscribe((data) => {
        if (data.success && data.result.length > 0) {
          this.totalList = data.result;
          if (this.listedFilterSat) {
            this.totalList = this.totalList
              .filter((e) => e.price > 0 && e.isListed)
              .sort((a, b) => b.price - a.price);
          } else {
            this.totalList.sort((a, b) => b.price - a.price);
          }
          this.nftList = this.totalList.slice(0, this.filterCount);
          this.totalCount = this.totalList.length;
        }
      });
    } else {
      await this.nftService.getAllAnimalNft().subscribe((data) => {
        if (data.success && data.result.length > 0) {
          this.totalList = data.result;
        }
      });
      await this.nftService.getAllGameNft().subscribe((data) => {
        if (data.success && data.result.length > 0) {
          this.totalList = this.totalList.concat(data.result);
          if (this.listedFilterSat) {
            this.totalList = this.totalList
              .filter((e) => e.price > 0 && e.isListed)
              .sort((a, b) => b.price - a.price);
          } else {
            this.totalList.sort((a, b) => b.price - a.price);
          }
          this.nftList = this.totalList.slice(0, this.filterCount);
          this.totalCount = this.totalList.length;
          console.log('total', this.totalList);
        }
      });
    }
  }

  async filterNftByPrice(min, max) {
    this.filterCount = 12;
    this.totalList = null;
    this.nftList = null;
    try {
      if (this.animalFilterStat && !this.gameFilterStat) {
        await this.nftService.getAllAnimalNft().subscribe((data) => {
          if (data.success && data.result.length > 0) {
            this.totalList = data.result;
            this.totalList.sort((a, b) => b.price - a.price);
            this.totalList = this.totalList.filter((item) => {
              return item.price >= min && item.price <= max;
            });

            this.nftList = this.totalList.slice(0, this.filterCount);
            this.totalCount = this.totalList.length;
          }
        });
      } else if (!this.animalFilterStat && this.gameFilterStat) {
        await this.nftService.getAllGameNft().subscribe((data) => {
          if (data.success && data.result.length > 0) {
            this.totalList = data.result;
            this.totalList.sort((a, b) => b.price - a.price);
            this.totalList = this.totalList.filter((item) => {
              return item.price >= min && item.price <= max;
            });

            this.nftList = this.totalList.slice(0, this.filterCount);
            this.totalCount = this.totalList.length;
          }
        });
      } else {
        await this.nftService.getAllAnimalNft().subscribe((data) => {
          if (data.success && data.result.length > 0) {
            this.totalList = data.result;
          }
        });
        await this.nftService.getAllGameNft().subscribe((data) => {
          if (data.success && data.result.length > 0) {
            this.totalList = this.totalList.concat(data.result);
            this.totalList.sort((a, b) => b.price - a.price);
            this.totalList = this.totalList.filter((item) => {
              return item.price >= min && item.price <= max;
            });

            this.nftList = this.totalList.slice(0, this.filterCount);
            this.totalCount = this.totalList.length;
          }
        });
      }
    } catch (e) {}
  }

  async searchNft(key) {
    console.log('tr', key.trim().length);

    if (key.trim().length > 0) {
      this.modalService.dismissAll('search');
      var animalResult;
      var gameResult;
      var param = {
        param: key,
      };
      await this.nftService.findAnimal(param).subscribe(async (data) => {
        console.log(data);
        if (data.success) {
          animalResult = data.result;
        }
        await this.nftService.findEquipment(key).subscribe((data) => {
          console.log(data);
          if (data.success) {
            gameResult = data.result;
          }
          if (animalResult) {
            this.totalList = animalResult;
            this.totalList = this.totalList.concat(gameResult);
          } else {
            this.totalList = gameResult;
          }
          this.nftList = this.totalList;
          this.totalCount = this.nftList.length;
        });
      });
    }
  }
}
