import { Injectable } from '@angular/core';
import { ethers } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';
import { BlockchainService } from './blockchain.service';
import { WalletService } from './wallet.service';
import { environment } from 'src/environments/environment';
declare let window: any;

@Injectable({
  providedIn: 'root',
})
export class MarketplaceService {
  provider: Web3Provider;
  signer: any;
  marketContract: any;
  marketSigner: any;
  abiCoder;
  abi = environment.SVV_ABI.MARKETPLACE;
  marketaddress: string;

  constructor(private bc: BlockchainService, private wallet: WalletService) {
    try {
      this.provider = new ethers.providers.Web3Provider(window.ethereum);
      this.signer = this.provider.getSigner();
      this.marketContract = new ethers.Contract(
        environment.SVV_MARKETPLACE_ADDRESS,
        this.abi,
        this.provider
      );
      this.marketSigner = new ethers.Contract(
        environment.SVV_MARKETPLACE_ADDRESS,
        this.abi,
        this.signer
      );
      this.abiCoder = ethers.utils.defaultAbiCoder;
    } catch (err) {}
  }

  async checkEvents() {
    console.log('Listening to event');

    this.marketContract.on('PriceUpdated', (data, data1, data2, data3) => {
      console.log(`PriceUpdated: ${data} - ${data1} - ${data2} - ${data3}`);
      /*use this event listener to update state into db, 
      this allow background process and avoid long waiting at frontend, 
      data will be received background once the event emitted from the smart contract*/
    });
  }

  setTokenPrice(
    tokenAddress: string,
    tokenId: bigint,
    price: number
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.marketSigner
        .setSalePrice(
          tokenAddress,
          tokenId,
          ethers.utils.parseEther(price.toString())
        )
        .then((tx: any) => {
          tx.wait()
            .then((receipt: any) => {
              this.bc
                .getTimestamp(receipt.blockNumber)
                .then((time: any) => {
                  const sold = {
                    hash: receipt.transactionHash,
                    tokenAddress: receipt.events[0].args[0].toLowerCase(),
                    tokenId: receipt.events[0].args[1].toNumber(),
                    seller: receipt.events[0].args[2].toLowerCase(),
                    price: ethers.utils.formatEther(receipt.events[0].args[3]),
                    timestamp: time,
                  };
                  resolve(sold);
                })
                .catch((err: Error) => reject(err));
            })
            .catch((err: Error) => reject(err));
        })
        .catch((err: Error) => reject(err));
    });
  }

  getTokenPrice(tokenAddress: string, tokenId: bigint): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.marketContract
        .salePrice(tokenAddress, tokenId)
        .then((res: any) => {
          const price = ethers.utils.formatEther(res);
          resolve(price);
        })
        .catch((err: Error) => {
          reject(err);
        });
    });
  }

  purchase(tokenAddress: string, tokenId: number) {
    return new Promise<any>((resolve, reject) => {
      this.getTokenPrice(tokenAddress, BigInt(tokenId))
        .then((price: any) => {
          console.log('token price', price);
          this.marketSigner
            .purchase(tokenAddress, tokenId, {
              value: ethers.utils.parseEther(price.toString()),
            })
            .then((tx: any) => {
              tx.wait()
                .then((receipt: any) => {
                  console.log(receipt);
                  this.bc
                    .getTimestamp(receipt.blockNumber)
                    .then((time: any) => {
                      for (
                        let index = 0;
                        index < receipt.events.length;
                        index++
                      ) {
                        if (receipt.events[index].event == 'Sold') {
                          const sold = {
                            hash: receipt.transactionHash,
                            tokenAddress:
                              receipt.events[index].args[0].toLowerCase(),
                            tokenId: receipt.events[index].args[1].toNumber(),
                            seller: receipt.events[index].args[2].toLowerCase(),
                            buyer: receipt.events[index].args[3].toLowerCase(),
                            price: ethers.utils.formatEther(
                              receipt.events[index].args[4]
                            ),
                            timestamp: time,
                          };
                          resolve(sold);
                        }
                      }
                    })
                    .catch((err: Error) => reject(err));
                })
                .catch((err: Error) => {
                  reject(err);
                });
            })
            .catch((err: Error) => {
              reject(err);
            });
        })
        .catch((err: Error) => {
          console.log('token price', err);
          reject(err);
        });
    });
  }

  makeOffer(tokenAddress: string, tokenId: number, price: number) {
    return new Promise<any>((resolve, reject) => {
      this.marketSigner
        .makeOffer(tokenAddress, BigInt(tokenId), {
          value: ethers.utils.parseEther(price.toString()),
        })
        .then((tx: any) => {
          tx.wait()
            .then((receipt: any) => {
              this.bc
                .getTimestamp(receipt.blockNumber)
                .then((time: any) => {
                  for (let index = 0; index < receipt.events.length; index++) {
                    if (receipt.events[index].event == 'OfferSubmitted') {
                      const offerMade = {
                        hash: receipt.transactionHash,
                        tokenAddress:
                          receipt.events[index].args[0].toLowerCase(),
                        tokenId: receipt.events[index].args[1].toNumber(),
                        buyer: receipt.events[index].args[2].toLowerCase(),
                        price: ethers.utils.formatEther(
                          receipt.events[index].args[3]
                        ),
                        timestamp: time,
                      };
                      resolve(offerMade);
                    }
                  }
                  // if (receipt.events.length > 4) {
                  //   const offerMade = {
                  //     hash: receipt.transactionHash,
                  //     tokenAddress: receipt.events[3].args[0].toLowerCase(),
                  //     tokenId: receipt.events[3].args[1].toNumber(),
                  //     buyer: receipt.events[3].args[2].toLowerCase(),
                  //     price: ethers.utils.formatEther(receipt.events[3].args[3]),
                  //     timestamp: time
                  //   }
                  //   resolve(offerMade);
                  // }

                  // const offerMade = {
                  //   hash: receipt.transactionHash,
                  //   tokenAddress: receipt.events[2].args[0].toLowerCase(),
                  //   tokenId: receipt.events[2].args[1].toNumber(),
                  //   buyer: receipt.events[2].args[2].toLowerCase(),
                  //   price: ethers.utils.formatEther(receipt.events[2].args[3]),
                  //   timestamp: time
                  // }
                  // resolve(offerMade);
                })
                .catch((err: Error) => reject(err));
            })
            .catch((err: Error) => {
              reject(err);
            });
        })
        .catch((err: Error) => {
          reject(err);
        });
    });
  }

  acceptOffer(tokenAddress: string, tokenId: number) {
    return new Promise<any>((resolve, reject) => {
      this.marketSigner
        .acceptOffer(tokenAddress, BigInt(tokenId))
        .then((tx: any) => {
          tx.wait()
            .then((receipt: any) => {
              this.bc
                .getTimestamp(receipt.blockNumber)
                .then((time: any) => {
                  for (let index = 0; index < receipt.events.length; index++) {
                    if (receipt.events[index].event == 'OfferAccepted') {
                      const offerAccept = {
                        hash: receipt.transactionHash,
                        tokenAddress:
                          receipt.events[index].args[0].toLowerCase(),
                        tokenId: receipt.events[index].args[1].toNumber(),
                        // seller: receipt.events[index].args[2].toLowerCase(),
                        buyer: receipt.events[index].args[2].toLowerCase(),
                        // price: ethers.utils.formatEther(receipt.events[index].args[4]),
                        timestamp: time,
                      };
                      resolve(offerAccept);
                    }
                  }
                })
                .catch((err: Error) => reject(err));
            })
            .catch((err: Error) => {
              reject(err);
            });
        })
        .catch((err: Error) => {
          reject(err);
        });
    });
  }

  cancelOffer(tokenAddress: string, tokenId: number) {
    return new Promise<any>((resolve, reject) => {
      this.marketSigner
        .cancelOffer(tokenAddress, BigInt(tokenId))
        .then((tx: any) => {
          tx.wait()
            .then((receipt: any) => {
              this.bc
                .getTimestamp(receipt.blockNumber)
                .then((time: any) => {
                  // const data = this.abiCoder.decode(["address"], receipt.events[1].data);
                  for (let index = 0; index < receipt.events.length; index++) {
                    if (receipt.events[index].event == 'OfferCanceled') {
                      const offerCancel = {
                        hash: receipt.transactionHash,
                        tokenAddress:
                          receipt.events[index].args[0].toLowerCase(),
                        tokenId: receipt.events[index].args[1].toNumber(),
                        buyer: receipt.events[index].args[2].toLowerCase(),
                        timestamp: time,
                      };
                      resolve(offerCancel);
                    }
                  }
                })
                .catch((err: Error) => reject(err));
            })
            .catch((err: Error) => {
              reject(err);
            });
        })
        .catch((err: Error) => {
          reject(err);
        });
    });
  }
}
