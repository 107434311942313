import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})

/**
 * Pagination Component
 */
export class PaginationComponent {
  
  @Input() anchorEle?: HTMLElement;
  @Input() page : number = 0;
  @Input() maxPage : number = 0;
  
  @Output() pageChange : EventEmitter<number> = new EventEmitter<number>();
  
  constructor() { }
  
  changePage(page: number) {
  // scrolling
  if (this.anchorEle) {
    const top = (this.anchorEle.offsetTop || 0) + (this.anchorEle.clientTop || 0)
      + (this.anchorEle.scrollTop || 0);
    window.scrollTo({
      //behavior: "smooth",
      top: top || 0
    });
  }
  this.pageChange.emit(page);
  }
}
