import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
declare const $:any;
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  faSearch,
  faEye,
  faShareAlt,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import {
  faFacebookF,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import Swal from 'sweetalert2';
// import { AngularFirestore } from '@angular/fire/firestore';
import { UsersService } from '../services/users.service';
import { NftService } from '../services/nft.service';
import { ClipboardService } from 'ngx-clipboard';
import { ClaimContractService } from '../services/claim-contract.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  tabName: any = localStorage.getItem('profile');
  @ViewChild('historyTable', {static: false}) historyTable:any;

  @Input() tab: 'account' | 'inventory' | 'wallet' | 'history' = this.tabName;
  @Input() tabInventory: 'savanaLife' | 'readyToMint' | 'listed' | 'activity' =
    'savanaLife';
  @Input() tabHistory: 'transactionHistory' | 'balanceHistory' =
    'transactionHistory';

  constructor(
    private modalService: NgbModal,
    private router: Router,
    // private afs: AngularFirestore,
    private userService: UsersService,
    private nftService: NftService,
    private _clipboardService: ClipboardService,
    private claimNftService: ClaimContractService
  ) {}

  faSearch = faSearch;
  faHeart = faHeart;
  faEye = faEye;
  faShareAlt = faShareAlt;
  faPen = faPen;
  faFacebookF = faFacebookF;
  faTwitter = faTwitter;
  faInstagram = faInstagram;
  userNftList;
  nftList;
  mintList;
  listedList;
  offChainList;
  historyList;
  createdList;
  collectedList;
  favouriteList;
  userProfile;
  detail;
  userAdd;
  files: File[] = [];
  username;
  email;
  bio;
  image;
  errMsg;
  claimText = 'Retrieve coupon';
  claimStatus = false;

  form!: FormGroup;

  historyPage: number = 0;
  historyPaginate: number = 10;

  async ngOnInit(): Promise<void> {
    this.userAdd = localStorage.getItem('account_address');
    this.userService.checkMetaMask();
    await this.getUserInfo();
    await this.getNftbyUserId();
    await this.getTradingHistoryByAddress();

    this.form = new FormGroup({
      username: new FormControl(""),
    })
  }

  getUserInfo() {
    this.userService.connectAccount().subscribe((data) => {
      if (data.success) {
        this.userService
          .getUserDetailByWalletAdd(this.userAdd)
          .subscribe((data) => {
            console.log('info', data);
            this.userProfile = data.result;
            this.getGameNfyByPlayerId(this.userProfile.player_id);
            this.username = data.result.username;
            if(this.username){
              this.form.patchValue(this.userProfile);
            }
            if (data.result.image) {
              this.image =
                'https://nftapi.wowoonet.com/user/img/' + this.userAdd;
            } else {
              this.image = '../assets/images/profile.jpg';
            }
            this.userService
              .getUserDetailByPlayerId(this.userProfile.player_id)
              .subscribe((res) => {
                if (data.success) {
                  this.email = data.result.email;
                }
              });
          });
      } else {
        console.log('failed');
      }
    });
  }

  getNftbyUserId() {
    var param = {
      owner: this.userAdd,
    };
    this.nftService.getNftByUserAddress(param).subscribe((data) => {
      console.log('byid', data);
      if (data.success) {
        this.nftList = data.result;
        this.createdList = data.result.created;
        this.collectedList = data.result.collected;
        this.favouriteList = data.result.favourite;
      }
      this.getGameNftByOwner();
    });
  }

  getGameNftByOwner() {
    this.nftService.getGameNftByOwner(this.userAdd).subscribe((data) => {
      if (data.success && data.result.length > 0) {
        if (this.nftList) {
          this.nftList = this.nftList.concat(data.result);
        } else {
          this.nftList = data.result;
        }
      }
      this.mintList = this.nftList.filter((item) => {
        return !item.isListed;
      });
      this.listedList = this.nftList.filter((item) => {
        return item.isListed;
      });
      console.log('listed', this.listedList);
      console.log('mintList', this.mintList);
    });
  }

  getGameNfyByPlayerId(playerId) {
    this.nftService.getGameNftByPlayerId(playerId).subscribe((data) => {
      if (data.success) {
        console.log('offchain', data);
        this.offChainList = data.result;
      } else {
        console.log(22);
        console.log(this.offChainList);
      }
    });
  }

  getTradingHistoryByAddress() {
    this.nftService.tradingHistoryByAddress(this.userAdd).subscribe((data) => {
      console.log('BySeller', data);
      if (data.success) {
        this.historyList = data.result;
      }
      $(this.historyTable.nativeElement).dataTable().fnDestroy();
          setTimeout(()=>this.ngAfterViewInit(), 100)
    });
  }
    ngAfterViewInit(){
      $(this.historyTable.nativeElement).dataTable({
        "lengthMenu": [
          [10, 25, 50, -1, "All"],
          [10, 25, 50, "All", "All"]
        ],
        "columnDefs": [
          { "targets": 5, "searchable": false, "orderable": false, }
        ]
      });
    }

  showQuickview(content) {
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  open(content, type) {
    this.modalService.open(content, { size: type, centered: true });
  }

  productDetail() {
    this.router.navigate(['product/detail']);
  }

  onSelect(event) {
    this.files[0] = event.addedFiles[0];
    console.log('img', this.files[0]);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  uploadImage() {
    const formData = new FormData();
    if (this.files.length !== 0) {
      var extn = this.files[0].name.split('.');
      console.log('file', extn[1]);
      let valid = ['jpg', 'jpeg', 'png'];
      var id = 1;
      if (valid.includes(extn[1].toLowerCase().toString())) {
        formData.append('_id', '1');
        formData.append('image', this.files[0]);
        this.userService.uploadImage(formData).subscribe((data) => {
          if (data.success) {
            console.log(data);
          } else {
            console.log(998);
          }
        });
      } else {
        this.errMsg = 'File types supported: JPG, JPEG, PNG only.';
      }
    }
  }

  async onSave(name) {
    const data = {
      username: name,
    };

    await this.userService.updateUser(data, this.userAdd).subscribe((data) => {
      console.log('update', data);
      if (data.result.success) {
        this.getUserInfo();
        this.modalService.dismissAll();
        Swal.fire({
          title: 'Success!',
          text: 'Your Account has been updated!',
          icon: 'success',
        });
      }
    });
  }

  onSubmit(form) {
    const data = { ...this.form.value };
    console.log("data", data)
    this.userService.updateUser(data, this.userAdd).subscribe((data) => {
      if (data) {
        this.getUserInfo();
        this.modalService.dismissAll();
        Swal.fire({
          title: 'Success!',
          text: 'Your Account has been updated!',
          icon: 'success',
        });
      }
    });
  }

  onClaimCoupon() {
    this.claimText = 'Coupon Ready';
    this.claimStatus = true;
  }

  onClaimNft() {
    if (this.claimStatus === true) {
      //after call claim nft api success reset status
      Swal.fire({
        title: 'Success!',
        text: 'You have minted your NFT!',
        icon: 'success',
      });
      this.modalService.dismissAll('claim');
      this.claimStatus = false;
      this.claimText = 'Retrieve coupon';
      console.log(this.claimStatus);
    }
  }

  callServiceToCopy() {
    this._clipboardService.copy(this.userProfile._id);
  }

  logout() {
    localStorage.removeItem('account_address');
    this.router.navigate(['product']).then(() => {
      window.location.reload();
    });
  }

  maxHistoryPage() {
    return Math.ceil(this.historyList?.length / this.historyPaginate);
 }
}
