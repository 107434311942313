import { Injectable } from '@angular/core';
import { Web3Provider } from '@ethersproject/providers';
import { ethers } from 'ethers';
import { environment } from 'src/environments/environment';
import { BlockchainService } from './blockchain.service';

declare let window: any;

@Injectable({
  providedIn: 'root',
})
export class ClaimContractService {
  provider: Web3Provider;
  signer: any;
  abi = environment.SVV_ABI.CLAIM_NFT;

  constructor(private bc: BlockchainService) {
    try {
      this.provider = new ethers.providers.Web3Provider(window.ethereum);
      this.signer = this.provider.getSigner();
    } catch (error) { }
  }

  //Params: claimTuple: string[], sigR: string, sigS: string, sigV: string
  claimEquipmentNft(claimTuple: string[], sigR: string, sigS: string, sigV: string): Promise<any> {
    const contract = new ethers.Contract(
      environment.SVV_CLAIM_NFT_ADDRESS,
      this.abi,
      this.signer
    );
    return new Promise((resolve, reject) => {
      contract
        .submitProofClaim(claimTuple, sigR, sigS, sigV)
        .then((tx: any) => {
          tx.wait()
            .then((receipt: any) => {
              console.log(receipt);
              this.bc
                .getTimestamp(receipt.blockNumber)
                .then((time: any) => {
                  const data = {
                    receipt,
                    timestamp: time,
                  };
                  //use this data object to update to the nft API
                  resolve(data);
                })
                .catch((err: Error) => reject(err));
            })
            .catch((err: Error) => reject(err));
        })
        .catch((err: Error) => reject(err));
    });
  }

  //Param: signer: string, claimTuple: string[], sigR: string, sigS: string, sigV: string
  verifySignature(signer: string, claimTuple: string[], sigR: string, sigS: string, sigV: string): Promise<string> {
    const contract = new ethers.Contract(
      environment.SVV_CLAIM_NFT_ADDRESS,
      this.abi,
      this.provider
    );
    return new Promise<string>(async (resolve, reject) => {
      var tuple = ["0x43978AA189bdd1221dD76571892e0f4CA856A254","0xB3F0DEe95dd3487C83b269F0a6A29783b37788e2",1111];
      contract
        // .verify("0x5BC02599aA5575ef2C30c5Bf4A6Ba3EE781429A7", tuple, "0x3b8eca30d0b60fdb6e9bd8d58948a07b7a308271f5d9f0e46cfd32c375bd8eba", "0x73884ff8f149f55efb908fb2ffe4cac64c3adb86973fa47cf27954461a86a04e", "27")
        .verify(signer, claimTuple, sigR, sigS, sigV)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: Error) => reject(err));
    });
  }
}
